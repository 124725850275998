import { PlussCore } from '../config/core';

const { Colours } = PlussCore;

const COLOUR_DUSK_LIGHT = Colours.COLOUR_DUSK_LIGHT;

const COLOUR_1 = '#011d5c';
const COLOUR_2 = '#3179cb';
const COLOUR_3 = '#1bb7ee';

const COLOUR_BRANDING_MAIN = '#4a57b7';
const COLOUR_BRANDING_DARK = '#364196';
const COLOUR_BRANDING_LIGHT = '#597db4';
const COLOUR_BRANDING_OFF = '#597db4';
const COLOUR_BRANDING_ACTION = '#5c90df';
const COLOUR_BRANDING_INACTIVE = '#dbddf1';

const TEXT_DARKEST = '#262a2d';
const TEXT_DARK = '#262a2d';
const TEXT_DARK_ALPHA50 = 'rgba(60, 60, 80, .5)';
const TEXT_DARK_ALPHA20 = 'rgba(60, 60, 80, .2)';
const TEXT_DARK_ALPHA10 = 'rgba(60, 60, 80, .1)';
const TEXT_MID = '#5a5a6e';
const TEXT_MID_ALPHA50 = 'rgba(90, 90, 110, .5)';
const TEXT_LIGHT = '#717b81';
const TEXT_SUPER_LIGHT = '#d2d9e5';
const COLOUR_DUSK = '#536280';
const COLOUR_DUSK_LIGHTER = '#a1afcc';

const LINEGREY = COLOUR_BRANDING_INACTIVE;
const BORDER_LINE_GREY = COLOUR_BRANDING_INACTIVE;

const COLOUR_TEAL = '#49bdbb';
const COLOUR_TEAL_ALPHA50 = 'rgba(4, 196, 169, 0.2)';
const COLOUR_BLUE = '#5e9efc';
const COLOUR_BLUE_ALPHA10 = 'rgba(0, 131, 238, 0.1)';
const COLOUR_DARKBLUE = '#191e78';
const COLOUR_PURPLE = '#6e79c5';
const COLOUR_RED = '#c02743';
const COLOUR_GREEN = '#00cc9c';
const COLOUR_TANGERINE = '#ff8c75';
const COLOUR_CITRON = '#bdbd27';
const COLOUR_GRAPEFRUIT = '#ff6363';
const DARK_OVERLAY = 'rgba(19,19,26,.9)';
const FACEBOOK_BLUE = '#3B5998';

const COLOUR_NAV_GREY = '#f6f6f6';
const COLOUR_TRANSPARENT = 'rgba(0,0,0,0)';
const COLOUR_SIDEBAR = '#303030';
const COLOUR_LIGHTGREY = '#8695b2';

const INACTIVE_TEXT = COLOUR_LIGHTGREY;

const COLOUR_BRANDING_APP = '#68bc49';

const hexToRGB = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const hexToRGBAstring = (hex, opacity) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})` : null;
};

const pctBtwnColours = (c1, c2, percentage) => {
  const rgbC1 = hexToRGB(c1);
  const rgbC2 = hexToRGB(c2);
  const r = rgbC2.r + (rgbC1.r - rgbC2.r) * percentage;
  const g = rgbC2.g + (rgbC1.g - rgbC2.g) * percentage;
  const b = rgbC2.b + (rgbC1.b - rgbC2.b) * percentage;
  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
};

export {
  COLOUR_1,
  COLOUR_2,
  COLOUR_3,
  TEXT_DARKEST,
  TEXT_DARK,
  TEXT_DARK_ALPHA50,
  TEXT_DARK_ALPHA20,
  TEXT_DARK_ALPHA10,
  TEXT_MID,
  TEXT_MID_ALPHA50,
  TEXT_LIGHT,
  TEXT_SUPER_LIGHT,
  COLOUR_DUSK,
  COLOUR_DUSK_LIGHT,
  COLOUR_DUSK_LIGHTER,
  LINEGREY,
  BORDER_LINE_GREY,
  COLOUR_TEAL,
  COLOUR_TEAL_ALPHA50,
  COLOUR_BLUE,
  COLOUR_BLUE_ALPHA10,
  COLOUR_DARKBLUE,
  COLOUR_PURPLE,
  COLOUR_RED,
  COLOUR_TANGERINE,
  COLOUR_CITRON,
  COLOUR_GRAPEFRUIT,
  DARK_OVERLAY,
  hexToRGB,
  hexToRGBAstring,
  pctBtwnColours,
  FACEBOOK_BLUE,
  COLOUR_NAV_GREY,
  COLOUR_TRANSPARENT,
  COLOUR_SIDEBAR,
  COLOUR_BRANDING_MAIN,
  COLOUR_BRANDING_OFF,
  COLOUR_BRANDING_LIGHT,
  COLOUR_BRANDING_DARK,
  COLOUR_BRANDING_ACTION,
  COLOUR_GREEN,
  INACTIVE_TEXT,
  COLOUR_LIGHTGREY,
  COLOUR_BRANDING_APP,
  COLOUR_BRANDING_INACTIVE,
};
