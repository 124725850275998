import config from './config.json';

export const versionNumber = config.version;

export const baseUrl = 'https://us-central1-aveo-dev.cloudfunctions.net';
//export const baseUrlAWS = "https://2mshak32he.execute-api.ap-southeast-2.amazonaws.com";
export const baseStage = 'prd';
export const baseClient = 'ldk';
export const clientName = 'LDK';
export const requireLogin = true;
export const requiresUserTermsAndConditions = true;
export const baseStorageUrl = `https://${baseClient}-${baseStage}-media.s3.ap-southeast-2.amazonaws.com/`;
export const baseUploadsUrl = `https://${baseClient}-${baseStage}-uploads.s3.ap-southeast-2.amazonaws.com/`;
export const baseLibraryUrl = `https://pluss60-dev-uploads.s3.ap-southeast-2.amazonaws.com/`;
export const baseAPIUrl = `https://api.ldk-lifestyle.com.au`;
export const defaultProfileImage =
  'https://pluss60-demo-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:485ccfd2-78bb-4844-9e22-ef6aac699881/public/a101d83d4ac0b4963a96aa65dd/defaultavatar.png';
export const logo =
  'https://pluss60-dev-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:e2dc6a8b-61e8-4a76-a7ea-b1a15a013b94/public/b8024c404c468e44c8fcc931bd/logo.png';

export const communityConfig = {
  banner:
    'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a07f89944830bd08ab481efbe6/plussbanner3.png',
};

export const eventsHaveTags = true;
export const newsHaveTags = true;
export const requiresEmail = false;
export const contentSource = 'pluss60-demo';
export const contentSourceSite = 'SuperHQ';

export const hasFeaturePicker = true;
export const hasAvailableNews = false;
export const hasAvailableEvents = true;
export const DEFAULT_ALLOW_COMMENTS = true;

export const testflightLink = '';
export const androidApk = '';

export const iOSLink = 'https://apps.apple.com/tt/app/pluss-communities/id1503581167';
export const androidLink = 'https://play.google.com/store/apps/details?id=com.pluss.prd';

export const AWS_AUTH_CONFIG = {
  userPoolId: 'ap-southeast-2_SVSj48Nit',
  userPoolWebClientId: 'u256u136b3op21ssatouos5th',
  region: 'ap-southeast-2',
  identityPoolId: 'ap-southeast-2:a77e7864-8e90-44d8-82f8-74bbf97b1f79',
  mandatorySignIn: false,
};

export const AWS_STORAGE_CONFIG = {
  bucket: `${baseClient}-${baseStage}-media`,
  region: 'ap-southeast-2',
  identityPoolId: AWS_AUTH_CONFIG.identityPoolId,
  customPrefix: {
    public: '',
  },
};

export const AWS_UPLOADS_BUCKET = `${baseClient}-${baseStage}-uploads`;

export const GA_TRACKING_ID = 'UA-185105694-1';

export const MAKE_API_KEY = '1162b2f93e93eb238e3e29aa602c60369dc6c297';

export const canCopyDummy = true;

export const UTC_OFFSET = 10;

export const paymentConfig = {
  stripeScript: 'https://js.stripe.com/v3/', // stripe
  commBankScript: 'https://paymentgateway.commbank.com.au/form/version/54/merchant/TESTPLUCOMEVAL01/session.js', // commBank
};

export const automationConfig = {
  awsAccountEmail: 'aws-account@plusscommunities.com',
  devAccountEmail: 'developer@plusscommunities.com',
  helpDeskEmail: 'helpdesk@plusscommunities.com',
  websiteUrl: 'https://plusscommunities.com',
  privacyUrl: 'https://plusscommunities.com/privacy',
  appleTester: 'apple@test.com',
  googleTester: 'google@test.com',
  // whiteLabelUrl: 'https://dev.pluss60-api.com',
  // whiteLabelStage: 'dev',
  whiteLabelUrl: 'https://pluss60.pluss60-api.com',
  whiteLabelStage: 'demo',
};
